import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './Intro.scss'

function Intro() {
	const slidesData = [
		{
			bgBig: '/images/alcospase_big.jpg',
			bgMedium: '/images/alcospase_medium.jpg',
			bgSmall: '/images/alcospase_small.jpg',
			title: 'AlcoSpace',
			text: 'This is Your space',
			style: {
				backgroundImage: 'url("/images/alcospase_medium.jpg")'
			}
		},
		{
			bgBig: '/images/alcoglass_big.jpg',
			bgMedium: '/images/alcoglass_medium.jpg',
			bgSmall: '/images/alcoglass_small.jpg',
			title: 'No matter where you are',
			text: 'Here you will not be alone',
			style: {
				backgroundImage: 'url("/images/alcoglass_medium.jpg")'
			}
		},
	]

	let itemRef = React.useRef(slidesData.map(() => React.createRef()))

	const slides = slidesData.map(
		(slide, index) => {
			return (
				<Carousel.Item
					key={index}
					ref={itemRef.current[index]}
				>
					<div
						className="carousel-bg"
						aria-hidden="true"
						style={slide.style}
					/>
					<Carousel.Caption>
						<h2 className="carousel-title">{slide.title}</h2>
						<p className="carousel-lead lead mb-0">{slide.text}</p>
					</Carousel.Caption>
				</Carousel.Item>
			)
		}
	)

	const getLastSlide = () => {
		return slidesData.length - 1
	}

	const [index, setIndex] = React.useState(getLastSlide)

	const handleSelect = (selectedIndex) => {
		let element = itemRef.current[index].current
		element.classList.add("sliding")
		setTimeout(() => {
			setIndex(selectedIndex)
		}, 600)
	}


	const carouselStart = () => {
		setIndex(0)
	}

	React.useEffect(() => {
		carouselStart()
	}, [])

	return (
		<Carousel
			className="carousel_intro"
			fade={true}
			activeIndex={index}
			onSelect={handleSelect}
			pause={false}
			controls={false}
			indicators={true}
		>
			{slides}
		</Carousel>
	)
}

export default Intro
