import React from 'react'
import Mailto from 'react-protected-mailto'
import './Footer.scss'

function Footer() {
	const requisites = {
		email: 'info@alcospace.com'
	}
	return (
		<footer className="footer container">
			<p className="text-center">
				Have a question? Email us: <Mailto
					email={requisites.email}
				/>
			</p>
		</footer>
	)
}

export default Footer